import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/Header';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from './App';
import {TransactionTable as SteemTable} from './views/Steem';
import {TransactionTable as BlurtTable} from './views/Blurt';
import {TransactionTable as SereyTable} from './views/Serey';
import Errors from './views/Errors';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Header />
    <Routes>
      <Route path="/" element={<App />}/>
      <Route path="/blurt" element={<BlurtTable />} />
      <Route path="/serey" element={<SereyTable />} />
      <Route path="/steem" element={<SteemTable />} />
      <Route path ="/errors/:network" element={<Errors />} />
    </Routes>
  </BrowserRouter>
  </React.StrictMode>
);

