import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Button, TextField } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { getTransactions, retryTx } from '../../utils/apiCalls';
import { useParams } from "react-router-dom";

export default function TransactionTable(props) {
    const [apiPass, setApiPass] = useState("");
const [bscTxns, setBscTxns] = useState([]);
  const [steemTxns, setSteemTxns] = useState([]);
    const network = "serey";
    const retry = async (network, tx, apiPass, src) => {
        console.log(`retry tx : ${tx.id}`)
        const pass = apiPass;
        setApiPass('');
        const response = await retryTx(network, pass, src, tx.id);
        console.log(response)
        if(response.error) {
            alert(response.error)
        }

    }

  const params = useParams();

  useEffect(() => {
      async function fetch() {
          setBscTxns([]);
          setSteemTxns([]);
          const result = await getTransactions(network);

          setBscTxns(result.bsc);
          setSteemTxns(result.steem);
      }

      fetch();
  }, [setBscTxns, setSteemTxns])
  return (
      <Container>
          <TextField label="Api Password" variant="outlined" value={apiPass} onChange={(e) => setApiPass(e.target.value)}/>
          <Typography variant="h2" gutterBottom component="div">
          BSC to {network} Transactions
          </Typography>
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
              <TableHead>
              <TableRow>
                  <TableCell align="left">#</TableCell>
                  <TableCell align="right">Sender</TableCell>
                  <TableCell align="right">Receiver</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Excluded</TableCell>
                  <TableCell align="right">Action</TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {bscTxns.map((row) => (
                  <>
                  <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                  <TableCell component="th" scope="row">
                      {row.id}
                  </TableCell>
                  <TableCell align="right">{row.bscAddress}</TableCell>
                  <TableCell align="right">{row.steemAddress}</TableCell>
                  <TableCell align="right">{row.bTokenAmount}</TableCell>
                  <TableCell align="right">{row.isProccessed}</TableCell>
                  <TableCell align="right">{row.isExcluded}</TableCell>
                  <TableCell align="right">      
                          <IconButton aria-label="delete" onClick={() => {
                                retry(network, row, apiPass, 'bsc')
                            }}>
                              <SendIcon />
                          </IconButton>
                  </TableCell>
                  </TableRow>
                  </>
              ))}
              </TableBody>
          </Table>
          </TableContainer>

          <Typography variant="h2" gutterBottom component="div">
          {network} to BSC Transactions
          </Typography>
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
              <TableHead>
              <TableRow>
                  <TableCell align="left">#</TableCell>
                  <TableCell align="right">Sender</TableCell>
                  <TableCell align="right">Receiver</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Source TX</TableCell>
                  <TableCell align="right">Memo</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Refunded</TableCell>
                  <TableCell align="right">Action</TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {steemTxns.map((row) => (
                  <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                  <TableCell component="th" scope="row">
                      {row.id}
                  </TableCell>
                  <TableCell align="right">{row.sender}</TableCell>
                  <TableCell align="right">{row.receiver}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell align="right">{row.txid}</TableCell>
                  <TableCell align="right">{row.memo}</TableCell>
                  <TableCell align="right">{row.isProccessed}</TableCell>
                  <TableCell align="right">{row.isRefunded}</TableCell>
                  <TableCell align="right">      
                          <IconButton aria-label="delete" onClick={() => {
                                retry(network, row, apiPass, 'steem')
                            }}>
                              <SendIcon />
                          </IconButton>
                  </TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </Container>
  )
}