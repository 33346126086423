import * as React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink
  } from "react-router-dom";

export default function HeaderLink(props) {
    const { path, text } = props;
    return (
        <NavLink to={path} style={{margin : 20}}>
        {text}
        </NavLink>
    )
}
