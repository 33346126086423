import Container from '@mui/material/Container';
import HeaderLink from './components/Link';
export default function Header() {
    return (
        <Container style={{ height : '84px', display : 'flex', flexDirection : 'row', alignItems : 'center'}}>
            <HeaderLink path='/' text="Home" />
            <HeaderLink path='/steem' text="Steem Transactions" />
            <HeaderLink path='/blurt' text="Blurt Transactions" />
            <HeaderLink path='/serey' text="Serey Transactions" />
            <HeaderLink path='/errors/steem' text="Steem Errors" />
            <HeaderLink path='/errors/blurt' text="Blurt Errors" />
            <HeaderLink path='/errors/serey' text="Serey Errors" />
        </Container>
    )
}