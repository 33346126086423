import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {getErrors, getTransactions } from './utils/apiCalls';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1A2027',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#fff',
  minHeight : '100px',
  display : 'flex',
  flexDirection : 'column',
  alignItems : 'center',
  justifyContent : 'space-around'
}));

function App() {
  const [state, setState] = useState({
    transactions : [],
    errors : [],
  });

  useEffect(() => {
    async function fetch() {
      const txs = await getTransactions();
      const errors = await getErrors();
      setState({
        transactions : txs,
        errors : errors,
      });
    }
    fetch();
  }, [setState])
  return (
    <Container>
    </Container>
  );
}

export default App;
