import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { getErrors } from '../../utils/apiCalls';
import { useParams } from "react-router-dom";

export default function Errors() {
    const [errors, setErrors] = useState([]);
    const params = useParams();

    useEffect(() => {
        async function fetch() {
            const result = await getErrors(params.network);

            setErrors(result);
        }

        fetch();
    }, [setErrors])
    return (
        <Container>
            <Typography variant="h2" gutterBottom component="div">
            {params.network} Errors
            </Typography>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 450 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">TX ID</TableCell>
                    <TableCell align="right">Error</TableCell>
                    <TableCell align="right">Object</TableCell>
                    <TableCell align="right">Date</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {errors.map((row) => (
                    <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="right">{row.transactionID}</TableCell>
                    <TableCell align="right">{row.error}</TableCell>
                    <TableCell align="right">{row.object}</TableCell>
                    <TableCell align="right">{row.date}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </Container>
    )
}